@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-color: #6759ea;
  --secondary-color: #f0d53d;
  --main-bg-color: #f3f7ff;
  --secondary-bg-color: #f9fafe;
  --font-color-profile: #5e7fb1;
}
@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'),
    url(/static/media/NotoSans-Regular.fa11626f.ttf) format('ttf');
}

* {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h5 {
  color: #504993;
}
.home-text {
  color: #504993;
}
.textSidebar {
  color: gray;
}
.titleSidebar {
  color: #000;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-10 {
  margin: 10px !important;
}
.black {
  color: #000 !important;
}
.buttonConversation {
  text-align: center;
  width: 100%;
  color: white;
  background-color: #2c7be5;
  padding: 4px 3px;
  border: 0px !important;
}

.conversationLateral .inner {
  padding: 15px 5px;
  cursor: pointer;
  display: flex;
}
.conversationLateral .inner p {
  color: #000;
}
.imageProfileConversation {
  position: relative;
  display: inline-block;
}
.textConversation {
  position: relative;
  min-width: 0;
  font-size: 0.83333rem;
  color: #748194;
}
.buttonMenu {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #fff !important;
}
.crop-container {
  position: relative;
  height: 500px;
}
.boxed {
  border-radius: 4px;
  border: 1px solid black !important;
  padding: 5px;
}
.button {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #fff !important;
  margin-top: 10px !important;
  text-align: center;
  cursor: pointer;
}
.button-white {
  background-color: #fff !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #6759ea !important;
  color: var(--main-color) !important;
  margin-top: 10px !important;
  text-align: center;
  cursor: pointer;
}
.buttonSmall {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 3px;
  padding: 5px 5px !important;
  color: #fff !important;
  margin-top: 5px !important;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
}
.selectionStartup {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #fff !important;
  text-align: center;
  cursor: pointer;
}
.activeConversation {
  border-left: 5px solid #00d27a;
  border-top: 1px solid #d8e2ef;
  border-bottom: 1px solid #d8e2ef;
}
.conversationListLateral {
  height: 350px;
  border-right: 1px solid #d8e2ef;
}
.listOfMessages {
  display: block;
  height: 250px;
  margin-top: 45px;
  margin-bottom: 45px;
}
.buttonWarning {
  background-color: #ea5858 !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #fff !important;
  margin-top: 10px !important;
  text-align: center;
  cursor: pointer;
}

.center {
  text-align: center;
  margin: 0 auto;
}
.headerPadding {
  padding-left: 24px;
  padding-right: 24px;
}
.noPadding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.width-100 {
  width: 100%;
}
.width-80 {
  width: 80%;
}
.width-20 {
  width: 20%;
}
.buttonSendMessage,
.inputMessage {
  float: left;
}
.inputMessage {
  padding: 0px 5px;
}
.separator-30 {
  margin-top: 30px !important;
}
.divider {
  margin: 30px 0px !important;
}

.search-home {
  width: 60%;
}
.logo-home {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}
.separationTop {
  margin-top: 20px;
}
.clear {
  clear: both;
}
.pointer {
  cursor: pointer;
}
.button-secondary {
  background-color: #f0d53d !important;
  background-color: var(--secondary-color) !important;
}

.form .MuiFormControl-root {
  margin: 15px 15px 0px 15px;
}
.form .width-100 { /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}
.formOutline {
  border: 1px solid #bababb;
  border-radius: 4px;
}
.radiusImage {
  border-radius: 10px;
}
.boxHome {
  border-top: 5px solid #6759ea;
  border-top: 5px solid var(--main-color);
  box-shadow: 4px 4px 8px 0px #777777;
  position: absolute;
  left: 45%;
  margin-top: 75px !important;
  background-color: white;
  padding: 20px 50px;
}
.boxHome h2 {
  font-size: 45px;
  line-height: 1;
}
.sectionLogo {
  margin-top: 60px !important;
}
button:disabled {
  cursor: not-allowed;
}
.bgDetailImage {
  background-image: url(/static/media/curvas.5e3f6c6d.jpg);
  background-size: cover;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 49.4%;
}
.homeFullWidthSection {
  color: #fff;
  padding: 40px 60px;
}
.homeFullWidthSection p {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}
.homeFullWidthSection h2 {
  font-size: 90px;
}
.bold {
  font-weight: bold !important;
}
.homeTwoColumns {
  padding: 40px 0px;
}
.cardCustom {
  position: relative;
  width: 100%;
  padding: 100px 0px;
  cursor: pointer;
}
.cardCustom h4,
.cardCustom p {
  color: #fff;
}
.cardCustom h4 {
  padding: 10px 0px;
}

.cardCustom .readMore {
  position: absolute;

  bottom: 30px;
  left: 39%;
}
.cardCustom-1 {
  background: linear-gradient(
      rgba(102, 88, 234, 0.75),
      rgba(102, 88, 234, 0.75)
    ),
    url(/static/media/mark.9d802e05.jpg);
  background-size: cover;
}
.cardCustom-2 {
  background: linear-gradient(
      rgba(102, 88, 234, 0.75),
      rgba(102, 88, 234, 0.75)
    ),
    url(/static/media/course-thumb-1.092de084.jpg);
  background-size: cover;
}
.cardCustom-3 {
  background: linear-gradient(
      rgba(102, 88, 234, 0.75),
      rgba(102, 88, 234, 0.75)
    ),
    url(/static/media/work-with-us.e8b6ea04.jpg);
  background-size: cover;
}
.cardWrapper {
  padding: 10px 20px;
}
.contactImageFooter {
  background-image: url(/static/media/office-2.ad3486b7.jpg);
  background-size: cover;
}
.footerMenus {
  background-color: #6759ea;
  background-color: var(--main-color);
  background-size: cover;
  width: 100%;
  position: relative;
}
.centerFullWidth {
  margin: 0 auto;
}
.footerColumn {
  padding: 40px 10px;
  color: #fff;
}
.smallText {
  font-size: 12px;
}
.navigatorFooter p,
.navigatorFooter a {
  margin-bottom: 3px;
  color: #fff;
}
.footerSpace p,
.footerSpace a {
  margin-bottom: 3px;
  color: #fff;
}
.alice-carousel__stage .item {
  padding: 0px 20px;
}
.alice-carousel {
  margin-top: 10px;
  margin-bottom: 25px;
}
.footer .socialNetwork {
  padding: 50px 0px;
}
.footer {
  width: 100% !important;
  max-width: none !important;
  padding: 0px !important;
}
.kep-login-facebook {
  width: 100%;
}
#solutions {
  margin-top: 30px;
}
.presentationImage {
  background-image: url(/static/media/unicornio_background.9fc737ff.png);
  height: 100vh;
  align-items: center;
  background-position-x: -40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrowContainer {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.arrowNext {
  text-decoration: none;
  color: #fff;
  background-color: rgb(155, 155, 155);
  border-radius: 100%;
}
.arrowNext a {
  text-decoration: none;
  color: #fff;
}

.rightSideModalLogin,
.leftSideModalLogin {
  padding: 0px 20px;
}
.optionSquare {
  padding: 5px;
  border: 1px solid #6658ea;
  border-radius: 5px;
  background-color: #eaeaea;
  cursor: pointer;
}
.choosenOption,
.choosenOption p {
  background-color: #6759ea;
  background-color: var(--main-color);
  color: #fff !important;
}
.choosenOption h5,
.footer h5,
.cardWrapper h4,
.bgDetailImage h2 {
  color: #fff !important;
}
.containerOptionSquare {
  padding: 5px;
}

.padding20 {
  padding: 20px 0px;
}
.conversationSubject {
  font-size: 12px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem !important;
}
.conversationName {
  font-size: 10px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem !important;
}
.title01 {
  font-size: 20px !important;
  font-weight: bold !important;
  color: gray !important;
}
.rounded-image {
  border-radius: 100%;
}
.profile-image {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

.nameCompanyProfile {
  padding: 50px 0px;
  font-size: 25px;
  padding-left: 10px;
}
.boxLinks {
  padding: 15px 0px;
}
.informationSmall {
  font-size: 10px;
  color: #6759ea;
  color: var(--main-color);
}
.formDelimiter {
  border-radius: 1.5rem !important;
  margin: 2rem 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.formDelimiter .MuiStepper-alternativeLabel {
  border-radius: 1.5em 1.5em 0px 0px;
}
.sidebarProfile .MuiDrawer-paper {
  position: static !important;
  position: initial !important;
}
.modalCustom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerloader {
  margin: 0 auto;
  text-align: center;
}
.btn-menu_sidebar {
  display: none;
}
.sidebarProfilesOpen {
  display: block;
}

.fixed {
  position: fixed;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.z-20 {
  z-index: 20;
}
.block {
  display: block;
}
.transition-opacity {
  transition-property: opacity;
}
.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}
.opacity-50 {
  opacity: 0.5;
}
.profileBlock {
  display: block !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-color: #6759ea !important;
  border-color: var(--main-color) !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.selectedCarousel .iconCarousel {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 50%;
  color: #fff;
  height: 60px;
  width: 60px;
  text-align: center;
  margin: 0 auto;
}
.iconCarousel svg {
  margin-top: 12px;
  fill: #6759ea !important;
  fill: var(--main-color) !important;
}

.selectedCarousel .iconCarousel svg {
  fill: #fff !important;
}
.mainColorBg {
  background-color: #f3f7ff;
  background-color: var(--main-bg-color);
}
.secondaryColorBg {
  background-color: #f9fafe;
  background-color: var(--secondary-bg-color);
}
.profileUser .MuiContainer-maxWidthLg {
  padding-left: 0px;
  padding-right: 0px;
}

.profileUser .sidebarProfile {
  padding-top: 10px;
  padding-left: 5px;
}

.titleSecondary {
  color: #6083b4 !important;
}
.spacingComponents {
  padding: 20px;
}
.MuiAccordion-rounded {
  margin-bottom: 10px;
}
.MuiCollapse-wrapperInner {
  background-color: #f9fafe;
  background-color: var(--secondary-bg-color);
}
.startupCover {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  min-height: 150px;
  position: relative;
  margin-bottom: 42px;
}
.statupCoverTitle {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.statupCoverTitle h2 {
  font-size: 40px;
  color: #fff !important;
  text-align: center;
}
.startupCoverCircle {
  position: absolute;
  bottom: -40px;
  left: 17px;
}
.startupCoverCircle img {
  width: 90px;
  border-radius: 100%;
  background-color: #fff !important;
  border: 2px solid white;
}
.boxbuttons {
  float: right;
  display: flex;
}
.boxbuttons div {
  margin-right: 10px;
}
.boxbuttons div span {
  margin-right: 4px;
}
.messageToRead {
  position: relative;
}
.messageBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.5rem;
  top: -0.495rem;
  height: 1rem;
  width: 1rem;
  font-size: 0.67rem;
  color: #fff !important;
  font-weight: 700;
  background-color: red;
  border-radius: 100%;
}
.buttonMessage,
.buttonProfile {
  cursor: pointer;
}
.selectionStartup {
  min-width: none !important;
  width: 100%;
}
.borderShadow {
  box-shadow: 4px 4px 8px 0px #777777;

  background-color: white;
  padding: 20px 50px;
}
.borderShadow2 {
  box-shadow: 4px 4px 8px 0px #777777;

  background-color: white;
  padding: 10px 10px;
}
.boxbuttons {
  margin-bottom: 10px;
}
.profileBlock p,
.profileBlock h1,
.profileBlock h2,
.profileBlock h3,
.profileBlock span,
.profileBlock label {
  color: #5e7fb1;
}

.profileBlock button span {
  color: #fff;
}
.MuiStepLabel-labelContainer span {
  color: #000 !important;
}
.quill {
  background-color: #fff;
}
#menu-profile-top {
  margin-top: 45px;
}
.carouselStartup .carousel-control-prev-icon,
.carouselStartup .carousel-control-next-icon {
  display: none;
}
.bodyWrapper {
  min-height: 100vh;
}
.companyDirectoryImage {
  max-width: 300px;
  max-height: 300px;
}
.otherUser {
  float: left;
  width: 70%;
}
.otherUser .colorChat {
  background-color: #edf2f9;
  padding: 5px;
  margin: 3px;
  border-radius: 10px;
}
.otherUser .colorChat p {
  color: #5e6e82 !important;
  font-size: 0.83333rem;
}
.actualUser {
  float: right;
  width: 70%;
  margin-right: 6px;
}
.actualUser .colorChat {
  background-color: #2c7be5 !important;
  padding: 5px;
  margin: 3px;
  border-radius: 10px;
}
.otherUser p,
.actualUser p {
  color: #fff !important;
  margin-bottom: 0rem !important;
  font-size: 0.83333rem;
  word-break: break-all;
}
.otherUser p {
  text-align: left;
}
.actualUser p {
  text-align: right;
}
.conversationActiveMessages {
  position: relative;
}
.informationActualConversation {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid #d8e2ef;
  padding-left: 10px;
}
.informationActualConversation p {
  margin-bottom: 2px;
  font-size: 12px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem !important;
}
.inputForMessage {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.lastMessage .updatedDate {
  float: right;
  width: 100%;
  margin-right: 6px;
}
.lastMessage .updatedDate p {
  text-align: center;
  margin-bottom: 0px;
  color: #9da9bb !important;
  font-size: 0.69444rem;
}
.hideDesktop {
  display: none;
}
.hideMobile {
  display: block;
}
.roundLetter {
  background-color: gray;
  border-radius: 100%;
  text-transform: uppercase;
  font-size: 20px;
  height: 30px;
  width: 30px;
  text-align: center;
}
.iconBig {
  font-size: 90px !important;
}
.circleIcon {
  border: 3px solid #000;
  border-radius: 50%;
}
.buttonlink p {
  text-decoration: none;
}
.buttonlink:hover {
  text-decoration: none;
}
.margin-top-10 {
  margin-top: 10%;
}
.topBarMenu {
  margin-bottom: 10px;
  display: grid;
}
.noImage {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0 auto;
}
.noImage .icon {
  top: 50%;
  display: block;
  text-align: center;
  margin: 0 auto;
  min-height: 300px;
}
.notRead {
  font-weight: 900 !important;
}
.discount {
  position: relative;
}
.discount:before {
  position: absolute;
  content: '';
  left: 0;
  top: 42%;
  right: 0;
  border-top: 2px solid red;
  transform: rotate(-23deg);

  -webkit-transform: rotate(-23deg);
  transform: rotate(-23deg);
}
.discountText {
  font-weight: 600;
  font-size: 20px;
}
.priceAfterDiscount {
  font-size: 30px;
  font-weight: 900;
  text-decoration: underline;
}
.boxPrice {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  padding: 5px 20px;
  color: #fff;
  border-radius: 10px;
}
.boxPrice p {
  color: #fff;
}
.dicountPercentage {
  color: #f72a2a;
  font-size: 25px;
}
.serviceName {
  font-size: 1.6rem;
}
.serviceBox {
  padding: 10px;
}
.oddBox {
  border-radius: 10px;
  background-color: #f3f7ff;
}
.evenBox {
  border-radius: 10px;
  background-color: #ffffff;
}
.carouselStartup h2 {
  color: #6759ea !important;
  color: var(--main-color) !important;
}
.myAccountButton,
#buttonLoginTop {
  margin-right: 5px !important;
}
#number_credits {
  margin-top: 10px;
}
.white-color {
  color: #fff;
}
.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.videoPresentation {
  margin-top: 20px;
  padding-top: 20px;
}
.MuiTablePagination-actions .MuiIconButton-label {
  color: #6658ea;
}
.button:disabled {
  background-color: #9e9e9e !important;
  cursor: not-allowed !important;
}
.MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-label,
.MuiDataGrid-menuIcon .MuiIconButton-label {
  color: #000 !important;
}
.MuiDataGrid-row.Mui-odd {
  background-color: aliceblue;
}
.MuiDataGrid-columnsContainer {
  background-color: #cee7fd;
}
.homeTwoColumns p {
  text-align: justify;
  font-size: 18px;
  font-weight: 700;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #5fa561 !important;
}
.textDisabled {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
.formSpacing .MuiFormControl-root {
  margin-top: 10px;
}
.title_company {
  margin-top: 10px;
}
.image-payment {
  max-width: 150px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
  content: '8px';
  font-size: 8px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before {
  content: '8px';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before {
  content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before {
  content: '12px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before {
  content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before {
  content: '20px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before {
  content: '24px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32px';
  font-size: 32px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before {
  content: '32px';
}
.confirmationMessage {
  color: #3aa916 !important;
}
.bannerAdsLAteral {
  display: block;
}
.blog-card a {
  width: 100%;
}
.bannerAdsLAteral img {
  width: 100%;
}
.separatorTop {
  margin-top: 15px;
}
.pointer {
  cursor: pointer;
}
.ql-align-center {
  text-align: center;
}
.fromEditor p,
.fromEditor h1,
.fromEditor h2,
.fromEditor h3,
.fromEditor h4,
.fromEditor h5 {
  min-height: 1px;
}
.fromEditor br {
  display: none;
}
/* .button-nextStep {
  margin-top: 20px !important;
} */

.buttons-registration {
  text-align: center;
  margin-top: 20px !important;
}
.buttons-registration button {
  margin-right: 10px;
}
#formregister {
  margin: 0 auto;
}
.MuiInputLabel-formControl {
  top: -5px !important;
}
.MuiFormControl-root p {
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif !important;
}
/*
.MuiDataGrid-cell {
	position: relative !important;
	line-height: 1 !important;
	white-space: pre-wrap !important;
}
*/
/*      RESPONSIVE     */

@media only screen and (max-width: 1280px) {
  .bgDetailImage {
    left: 49.3%;
  }
  .boxHome {
    border-top: 5px solid #6759ea;
    border-top: 5px solid var(--main-color);
    box-shadow: 0px 0px 0px 0px #777777;
    position: inherit;
    left: 40%;
    margin-top: 0px !important;
    background-color: white;
    padding: 10px 25px;
  }
  .boxHome h2 {
    font-size: 30px;
    line-height: 1;
  }
  .radiusImage {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 960px) {
  .bgDetailImage {
    left: 49.1%;
  }
  .btn-menu_sidebar {
    display: block;
  }
  .boxHome {
    border-top: 5px solid #6759ea;
    border-top: 5px solid var(--main-color);
    box-shadow: 0px 0px 0px 0px #777777;
    position: inherit;
    left: 40%;
    margin-top: 0px !important;
    background-color: white;
    padding: 10px 25px;
  }
  .boxHome h2 {
    font-size: 30px;
    line-height: 1;
  }
  .radiusImage {
    border-radius: 0px;
  }
  .divider {
    margin: 10px 0px !important;
  }
  .presentationImage {
    background-position-x: -200px;
  }
}
@media only screen and (max-width: 768px) {
  .bgDetailImage {
    left: 49%;
  }
  .hideDesktop {
    display: block;
  }
  .hideMobile {
    display: none;
  }
  .cardWrapper {
    padding: 10px 0px;
  }
  .homeFullWidthSection h2 {
    font-size: 40px;
  }
  .search-home {
    width: 100%;
  }
  .presentationImage {
    background-position-x: -190px;
  }
}
@media only screen and (max-width: 720px) {
  .bgDetailImage {
    left: 48.5%;
  }
}
@media only screen and (max-width: 620px) {
  .bgDetailImage {
    left: 48%;
  }
  .bannerAdsLAteral {
    display: none;
  }
  .MuiStepper-alternativeLabel {
    display: block !important;
  }
  .MuiStepConnector-horizontal {
    display: none;
  }
  .fromEditor img {
    width: 100% !important;
  }
}
@media only screen and (max-width: 575px) {
  .bannerAdsLAteral {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .bgDetailImage {
    left: 47.8%;
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;

  background-size: 100%, 100%;
  border-radius: 50%;

  background-image: none;
}
.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon:after {
  content: '>';
  font-size: 35px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 35px;
  color: black;
}

.startup-features-1 {
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.management-team.member,
.companyBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  font-weight: 600;
  border: 1px solid;
}
.companyBox {
  position: relative;
}

.companyBoxWrapper {
  height: 100%;
  display: inline-grid;
}
.blog-card .companyBox {
  margin: 0px;
}

.noDecoration,
.noDecoration:hover {
  text-decoration: none;
}
.companyBox h4 {
  font-size: 1rem;
  font-weight: 700;
  color: #368eec;
}
.companyBox p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
}
.seeMore {
  text-align: center;
  position: relative;
  bottom: 0;
}
.seeMore p {
  font-weight: 700 !important;
}
.servicesBox {
  border: 1px solid #5e7fb1;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
}
.management-team.member a {
  text-decoration: underline;
}

.carousel-control-prev,
.carousel-control-next {
  max-width: 100px;
}

.product-price .price,
.product-price .discount,
.product-price .percentage {
  margin-left: 1rem;
}

.startup-tag {
  margin-right: 0.5rem;
  background: #6658ea;
  color: white;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 10px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.startup .MuiButton-text {
  color: #6759ea !important;
  color: var(--main-color) !important;
}
.product-name {
  font-size: 1.4rem;
  color: grey;
  font-weight: 500;
}

.industry-tag {
  background-color: #6759ea !important;
  background-color: var(--main-color) !important;
  border-radius: 3px;
  padding: 5px 20px !important;
  color: #fff !important;
  margin-top: 10px !important;
  text-align: center;
  cursor: pointer;
  margin: 0.75rem;
}

.industry-tag-selected {
  border-radius: 3px;
  border: 1px solid #6759ea !important;
  border: 1px solid var(--main-color) !important;
  padding: 5px 20px !important;
  color: #6759ea !important;
  color: var(--main-color) !important;
  margin-top: 10px !important;
  text-align: center;
  cursor: unset;
  margin: 0.75rem;
  width: 200px;
  font-size: 0.9rem;
}
.equis-oscar {
  position: absolute;
  top: 6px;
  right: 5px;
}

.jobOffer-txt {
  display: table;
  margin: 0 auto;
}

.job-conditions {
  width: 50%;
  margin-left: 40%;
}

.job-page-title {
  display: table;
  margin: 0 auto;
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.job-page-subtitle {
  display: table;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.forced-black {
  width: 100%;
}

.forced-black label,
.forced-black div {
  color: #5e7fb1 !important;
  color: var(--font-color-profile) !important;
}

.divider .margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.carousel-indicators {
  display: none;
}

.challenge-zone h4 {
  color: black;
}

.blog h3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.blog .comment {
  box-shadow: 0 1rem 3rem rgba(52, 58, 64, 0.175) !important;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  width: 80%;
  background: white;
  justify-content: center !important;
}

.blog .content {
  padding: 3rem;
  justify-content: center !important;
}

.blog .descriptionContent {
  background: white;
  box-shadow: 0 1rem 3rem rgba(52, 58, 64, 0.175) !important;
  padding: 1rem;
}

.help-create-startup {
  background: white;
  padding: 9px;
  border-radius: 35px;
}

.blog-content-card {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.6rem;
  margin-bottom: 1rem;
}

.comment .username {
  position: absolute;
  color: #6759ea;
  font-weight: 600;
}

.create-first-startup .content > img {
  width: 100% !important;
}

.blog .header-img {
  height: 400px;
  width: 100%;
}

.blog-card {
  float: left;
  padding: 0px 5px;
}

.blog-card .header-img {
  margin-bottom: 1rem;
}

.blog-card .title {
  margin-bottom: 1rem;
  height: 50px;
}

.ads-banner {
  max-height: 200px;
}

.limited-img {
  width: 100%;
  height: 400px;
  background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.limited-img.ad {
  height: 200px;
}

@media (max-width: 991px) {
  .limited-img {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .limited-img {
    height: 200px;
  }
}

@media (max-width: 450px) {
  .limited-img {
    height: 150px;
  }
}

.blog-ad-banner-a.Top.Horizontal {
  width: 100%;
}
.blog-ad-banner.Top.Horizontal {
  width: 100%;
}

.forumBox {
  border: 1px solid #5e7fb1;
  padding: 0.4rem;
  margin: 0.3rem;
  border-radius: 4px;
}

.forum .add-msg-box {
  padding: 1rem;
  margin: 1rem;
}

.forum .post {
  cursor: pointer;
}

.forum h3,
.forum h2 {
  margin-bottom: 1rem;
}

.forum .username,
.forum .date {
  font-size: 0.8rem;
}

.forum .date {
  float: right;
  margin-bottom: 3px;
  color: #6685b5;
}

.pr1 {
  padding: 0.5rem;
}

.mr1 {
  margin: 1rem;
}

.forum .msg .user {
  border-right: 1px solid #5e7fb1;
  padding: 1rem;
}

.forum .msg .date {
  margin-top: 0.5rem;
}

.forum .msg .body {
  font-size: 0.9rem;
  padding: 1rem;
  margin-left: 0.6rem;
  border-radius: 9px;
}

.forum .op.body {
  background: #2c7be5;
  color: white;
}

.forum .msg {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.forum .normal.body {
  background: #f5f2f9;
}

.forum .button {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.forum.create .new-msg {
  margin-top: 1rem;
}

.forum .title {
  font-weight: 600;
  color: #007bff;
}

.forum.thread .messages {
  border: 2px solid #f5f2f9;
  padding-left: 0.8rem;
  margin-bottom: 1rem;
}

.forum .pagination a {
  margin: 5px;
}

.forum .create {
  margin-bottom: 2rem;
  padding: 1rem;
}
.forum .help {
  margin-left: 1rem;
  cursor: pointer;
}

.forum .create-link {
  margin-bottom: 2rem;
}

.button.error {
  background: transparent !important;
  color: red !important;
  box-shadow: 0px 3px 1px -2px rgb(255, 3, 3), 0px 1px 1px 0px rgb(255, 3, 3),
    0px 1px 5px 0px rgb(255, 3, 3) !important;
  margin-right: 1rem !important;
}

.right {
  float: right;
}

.forum .name {
  font-size: 0.8rem;
}

/* .MuiButton-text {
  background-color: var(--main-color) !important;
} */

.aviso-legal ol,
.politica-cookies ol {
  list-style-type: none;
  padding-left: 0;
}

.politica-cookies table,
.politica-cookies th,
.politica-cookies td {
  border: 1px solid black;
}

.challenge-txt {
  padding: 1rem;
  border: 1px solid #3f51b5;
  margin: 1rem;
}

.seo-h1 {
  font-size: 1.5rem;
  font-weight: 1000;
}

.seo-h2 {
  font-size: 1rem;
  font-weight: 1000;
  color: rgba(0, 0, 0, 0.54);
}

.MuiDataGrid-toolbarContainer button {
  padding: 0.5rem;
  margin-top: 0.5rem;
  background: #6759ea !important;
  background: var(--main-color) !important;
  margin-bottom: 0.5rem;
}

